<template>
  <v-container class="px-0">
    <section class="col">
      <div class="col pa-0">
        <div v-if="retailOrderDetails.status === 'completed'" class="order__title">
          <p>Спасибо. Ваш заказ был принят.</p>
        </div>
        <div v-else class="order__title order__title_rejected">
          <p>Unfortunately, there was an error in processing the payment. Please try again.</p>
        </div>
        <div class="order__detail">
          <div class="order__number">
            <p class="mb-2">Номер заказа</p>
            <span>{{ retailOrderDetails?.order_number }}</span>
          </div>
          <div class="order__data">
            <p class="mb-2">Дата:</p>
            <span>{{ retailOrderDetails?.created_at }}</span>
          </div>
          <div class="order__email">
            <p class="mb-2">E-mail:</p>
            <span>{{ retailOrderDetails?.customer?.email }}</span>
          </div>
          <div class="order__total">
            <p class="mb-2">Всего:</p>
            <span>
              {{ sumTotal }}
              {{ retailOrderDetails?.sum_view?.KGS?.currency}}
              </span
            >
          </div>
        </div>
      </div>
      <div class="order__content">
        <div class="order__inner">
          <h2>Информация о заказе</h2>
          <v-expansion-panels class="mb-1" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="payment__product mb-0">
                <span>Product</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="item in retailOrderDetails?.order_item"
                  :key="item.id"
                  class="payment__goods px-0"
                >
                  <span>{{ item?.name?.ru }} × {{ item?.amount }}</span>
                  <span class="price">
                    {{ item?.sum }}
                    {{
                      retailOrderDetails?.sum_view[
                        retailOrderDetails.currency_code
                      ]?.currency
                    }}
                  </span>
                  <p
                    >Цвет:
                    {{
                      item.variations_data.find((i) => i.name.includes('Цвет'))
                        .option.value
                    }}</p
                  >
                  <p
                    >Размер:
                    {{
                      item.variations_data.find((i) =>
                        i.name.includes('Размер')
                      ).option.value
                    }}</p
                  >
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="payment__total-wrapper">
            <!-- <div class="payment__subtotal">
              <span>Subtotal</span>
              <span class="subtotal-price">$0</span>
            </div>
            <div class="payment__shipping">
              <span>Shipping</span>
              <span class="shipping-price">$0</span>
            </div> -->
          </div>
          <div class="order__payment-total">
            <span>Total</span>
            <span>
              {{ Number(this.retailOrderDetails?.sum) - Number(this.retailOrderDetails?.discount) }}
              {{
                retailOrderDetails?.sum_view[retailOrderDetails.currency_code]
                  ?.currency
              }}
              </span
            >
          </div>
          <div class="order__payment-total">
            <span>Delivery</span>
            <span>
              {{ retailOrderDetails?.delivery }}
              {{
                retailOrderDetails?.sum_view[retailOrderDetails.currency_code]
                  ?.currency
              }}
              </span
            >
          </div>
          <div class="order__address">
            <!-- <div class="payment__terminal">
              <h3>Платежный терминал</h3>
              <p
                >By placing an order, you accept our [get_rules],
                [get_politica_konf] and [get_return_and_exchange]. You also
                agree that TUMAR will store some of your data in order to
                improve the quality of your service the next time you shop on
                our site.</p
              >
            </div> -->
            <div class="delivery__address">
              <h3>Адрес доставки:</h3>
              {{ retailOrderDetails?.customer?.address }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('orderHistory')
const {
  mapActions: mapCartActions,
  mapState: mapCartState,
  mapMutations,
} = createNamespacedHelpers('storeCart')

export default {
  data() {
    return {
      order: {},
    }
  },
  computed: {
    ...mapState(['retailOrderDetails']),
    sumTotal() {
      return Number(this.retailOrderDetails?.sum) - Number(this.retailOrderDetails?.discount) + Number(this.retailOrderDetails?.delivery)

    }
  },
  methods: {
    ...mapActions(['getRetailOrderById']),
    ...mapMutations(['CLEAR_CART_ITEMS']),
  },
  async created() {
    const orderId = this.$route.params.id
    if (orderId) {
      const data = await this.getRetailOrderById({ orderId })
      
      if(data.status === "completed") {
        this.CLEAR_CART_ITEMS()
      }
    }
  },
}
</script>

<style></style>
